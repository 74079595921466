function HotelResultProcess(handler) {
    this.progressUrl = dataActive.location.marketplace + "/v1/search/progress";
    this.resultUrl = dataActive.location.marketplace + "/v1/search/results";
    this.currentCurrency;
    var intervalId;
    var progressFinished;
    var isProgressFinished = false;
    var delay = 3000;
    var waitTime = 60000;
    var me = this;
    var messageCode = "t<wt";
    var $resultItem = handler.domNode.find(".hotel-item");
    var $moreResult = handler.domNode.find(".more-result");
    var $waiting = handler.domNode.find(".search-loader");
    var $filters = handler.domNode.find('.hotel-filters');
    var $filtersForm;
    var $pageNumber;
    var counter = 0;
    var searchBtn = handler.domNode.prev().find('.service-search-btn');
    var headers = {};
    var loginInformation = getStorage("loginInformation");
    if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
       headers = { 'Authorization': 'Bearer ' + loginInformation.token };
    }

    searchBtn.prop('disabled', true);
    dataActive.hooks.register('afterAjaxError', this.afterAjaxError);

    this.checkProgress = function () {
        ajax({
            url: me.progressUrl,
            method: "get",
            data: {
                sessionId: dataActive.location.query.sessionId
            },
            headers: headers || {},
            success: function (data) {
                if (data.percent == 100) {
                    me.searchResult(messageCode + ",p=100");
                    isProgressFinished = true;
                }
            },
        });
    }

    this.progressFinished = function () {
        messageCode = "t>wt";
        if (isProgressFinished == false && counter < 2) {
            ajax({
                url: me.progressUrl,
                method: "get",
                data: {
                    sessionId: dataActive.location.query.sessionId
                },
                headers: headers || {},
                success: function (data) {
                    if (data.percent == 0) {
                        me.manageResults(messageCode + ",p=0")
                    } else if (data.percent < 100) {
                        me.searchResult(messageCode + ",p<100");
                    } else {
                        me.searchResult(messageCode + ",p=100");
                    }

                },
            });
        }
        else {
            me.kill();
        }
        counter = counter + 1;
    }

    this.searchResult = function (latestMessageCode) {
        ajax({
            url: me.resultUrl,
            method: "get",
            data: {
                sessionId: dataActive.location.query.sessionId
            },
            headers: headers || {},
            success: function (data) {
                searchBtn.prop('disabled', false);
                me.currentCurrency = data.queryParams['marketplace_currency'].abb;
                if (data.queryParams.destination_city.abb == "IST" || data.queryParams.destination_city.abb == "DXB" | data.queryParams.destination_city.abb == "LON") {
                    me.showWeather(data.queryParams.destination_city);
                }
                if (data.data.length == 0) {
                    me.manageResults(latestMessageCode + ",d=0");
                    return;
                }
                me.manageResults(latestMessageCode + ",d!=0", data);
            },
        })
    }

    this.moreResult = function () {
        $moreResult.hide();
        $waiting.show();
        $pageNumber.val(parseInt($moreResult.data('currentPage')) + 1);
        ajax({
            url: me.resultUrl,
            method: "get",
            data: $filtersForm.serialize(),
            headers: headers || {},
            success: function (data) {
                me.mapContent(data);
                $moreResult.data('currentPage', data.pages.page);
                if ($moreResult.data('pageCount') > parseInt(data.pages.page)) {
                    $moreResult.show();
                }
            },
        })
    }

    this.mapFilters = function (filters) {
        $filters.empty();
        $filters.each((index, item) => {
            if ($(window).width() > 992 && $(item).hasClass("desktop-filters")) {
                $(item).append(view("hotel/hotel-filters"));
            } else if ($(window).width() < 992 && $(item).hasClass("mobile-filters")) {
                $(item).append(view("hotel/hotel-filters"));
            }
        });

        $filtersForm = $filters.find('.hotel-filter-form');
        $pageNumber = $filtersForm.find(".page-number");
        var $newPriceSection = $filters.find(".price-section");
        $newPriceSection.html(view("partials/price-filter", { filters: filters }));
        priceSlider(me.currentCurrency);

        var $newDistrictsSection = $filters.find(".districts-section");
        var districtsItem = '<label class="icheck-label"> <input class="icheck" type="checkbox"  name="districts[***]" /><span class="icheck-title">***</span></label>';
        $newDistrictsSection.html(view("hotel/hotel-result-district", { filters: filters, districtsItem: districtsItem }));

        var $hotelClass = $filters.find(".hotel-class");
        $hotelClass.html(view('hotel/hotel-class-filter', { filters: filters }));

        checkboxes();
    }

    this.loadMore = function (pages) {
        if ($moreResult.data('pageCount') == undefined) {
            $moreResult.click(me.moreResult)
        }
        $moreResult.data('pageCount', pages.pageCount);
        $moreResult.data('currentPage', pages.page);
        if (pages.pageCount > parseInt(pages.page)) {
            $moreResult.show();
        } else {
            $moreResult.hide();
        }
    }

    this.applyFilters = function () {
        $resultItem.empty();
        $moreResult.hide();
        $waiting.show();
        $pageNumber.val(1);
        ajax({
            url: me.resultUrl,
            method: "get",
            data: $filtersForm.serialize(),
            headers: headers || {},
            success: function (data) {
                me.mapContent(data);
                $pageNumber.val(2);
                me.loadMore(data.pages);
            },
        })
    }

    this.configFilters = function (filters, pages) {
        $filters.show();
        $filters.find(".filter-by-title").on("keyup", function (event) {
            if (event.keyCode === 13) {
                me.applyFilters(event);
                $filters.next().click();
            }
        });

        $filters.find(".apply-filter").click(function () {
            me.applyFilters();
            $filters.next().click();
        });
        var $resetButton = $filters.find(".reset-filter");
        $resetButton.click(function () {
            $filtersForm[0].reset();
            $filters.empty();
            me.mapFilters(filters);
            me.configFilters(filters, pages);
            me.applyFilters();
            $filters.next().click();
        });

        $filters.find(".session-id").val(dataActive.location.query.sessionId);
        $pageNumber.val(1);
        this.loadMore(pages);
    }

    this.mapContent = function (data) {
        $waiting.hide();
        var $hotelItem = handler.domNode.find('.hotel-item');
        data.data.forEach(item => {
            $hotelItem.append(view('hotel/hotel-item', { item: item }));
        });
        handler.domNode.find(".search-count").removeClass('display-none').text(trans('ABOUT @1 HOTELS WERE FOUND IN @2!' + data.pages.resultCount + ',' + (data.queryParams.destination_city.title).toUpperCase()));
        setStorage("currencyAbbreviation", me.currentCurrency);
    }

    this.manageResults = function (latestMessageCode, data) {
        switch (latestMessageCode) {
            case "t<wt,p=100,d=0":
                me.showNotFoundError();
                me.kill();
                break;
            case "t<wt,p=100,d!=0":
                me.mapData(data);
                me.kill();
                break;
            case "t>wt,p=0":
            case "t>wt,p<100,d=0":
                me.showAlertQuestion("No Response!", "Wait More", "Try.Again!");
                break;
            case "t>wt,p<100,d!=0":
                $resultItem.empty();
                me.mapData(data);
                break;
            case "t>wt,p=100,d=0":
                me.showNotFoundError();
                me.kill();
                break;
            case "t>wt,p=100,d!=0":
                me.mapData(data);
                me.kill();
                break;
            case "t>wt,loaded,p=100,d!=0":
                me.alertReloade("More result found. Do  you wish to reload now?", "N0", "Yes");
                break;
            default:
                me.showNotFoundError();
                me.kill();
                break;
        }


    }

    this.showNotFoundError = function () {
        $waiting.hide();
        handler.domNode.find('.show-not-found-message').show();
    }

    this.showAlertQuestion = function (text, cancelText, confirmText) {
        swal({
            text: trans(text),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: trans(cancelText),
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: trans(confirmText),
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                }
            }
        })
            .then((response) => {
                if (response == true) {
                    searchBtn.click();
                }
            });
    }

    this.alertReloade = function (text, cancelText, confirmText) {
        swal({
            text: trans(text),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: trans(cancelText),
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: trans(confirmText),
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                }
            }
        })
            .then((response) => {
                if (response == true) {
                    me.kill();
                    ajax({
                        url: dataActive.location.marketplace + "/v1/search/refresh",
                        method: "get",
                        data: {
                            sessionId: dataActive.location.query.sessionId
                        },
                        success: function (filters) {
                            $resultItem.empty();
                            me.mapContent(data);
                            me.mapFilters(filters);
                            me.configFilters(filters, data.pages);

                        }
                    })
                }
            });
    }

    this.mapData = function (data) {
        me.mapContent(data);
        me.mapFilters(data.filters);
        me.configFilters(data.filters, data.pages);
    }

    this.kill = function () {
        clearInterval(intervalId);
        clearInterval(progressFinished);
        $('.progress-bar').hide();
    }

    this.afterAjaxError = function () {
        dataActive.pageRoute('index');
    };

    this.showWeather = function (city) {
        var cityId;
        if (city.abb == 'LON') {
            cityId = 2643743;
        } else if (city.abb == 'DXB') {
            cityId = 3435280;
        } else if (city.abb == 'IST') {
            cityId = 745044;
        }
        ajax({
            url: 'https://api.openweathermap.org/data/2.5/weather?id=' + cityId + '&units=metric&appid=6ab7aa801aa97e116e07d47b172d88ff',
            method: 'get',
            data: {},
            xhr: function () {
                var xhr = $.ajaxSettings.xhr();
                var setRequestHeader = xhr.setRequestHeader;
                xhr.setRequestHeader = function (name, value) {
                    if (name == 'pid') return;
                    setRequestHeader.call(this, name, value);
                }
                return xhr;
            },
            success: function (data) {
                handler.domNode.find('.weather-section').html(view('partials/weather-template', { data: data, city: city }));
            },
        });
    }

    ///>initial codes
    if ($(window).width() < 992) {
        mobileFilters();
        magnificLightbox();
    }

    if ($(window).width() > 992) {
        dataActive.hooks.register('afterPageRoute', function () {
            handler.domNode.find('.sticky-col').stick_in_parent({
                parent: '#sticky-parent',
            })
        });
    }

    this.checkProgress();
    $('.progress-bar-fill').delay(500).queue(function () {
        $(this).css('width', '100%');
    });
    if (!isProgressFinished) {
        intervalId = setInterval(this.checkProgress, delay);
        progressFinished = setInterval(this.progressFinished, waitTime);
    }

    dataActive.hooks.register('changeCurrency', function (currency) {
        if (!currencyChangeAlert()) {
            return;
        }
        if (!$.isEmptyObject(currency)) {
            dataActive.updateConfig('currentCurrency', currency);
            searchBtn.click();
        }
    });
    dataActive.hooks.register('changeLanguage', function (language) {
        if (languageChangeAlert()) {
            return language;
        }
    });
    dataActive.hooks.register('beforePageRoute', function (configs) {
        me.kill();
        return configs;
    });
}
