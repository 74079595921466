function EWalletChargeProcess(handler) {
    var me = this;
    this.gatewayItem;
    this.walletItems;
    let form = handler.domNode.find('form');
    let loginInformation = getStorage("loginInformation");
    let headers = {}
    if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
        headers = { 'Authorization': 'Bearer ' + loginInformation.token };
    }
 
    handler.domNode.find('.fetch-gateway').click(function () {
        let btn = $(this);
        ajax({
            url: dataActive.location.marketplace + "/v1/gateways",
            method: 'POST',
            data: form.serialize(),
            headers: { 'Authorization': 'Bearer ' + getStorage('loginInformation').token },
            success: function (data) {
                btn.attr('disabled', true);
                handler.domNode.find('.gateway-section-ewallet-charge').append(view('partials/gateway-list', { gateways: data.gateways, showInstructionLink: false, showBalance: false }));
                handler.domNode.find('.wallet-charge').removeClass('d-none');
                handler.domNode.find('.invoice-reference').val(data.invoiceReferenceId);
                let maxHighestBox = 0;
                handler.domNode.find('.gateway-item').each((index, item) => {
                    $item = $(item);
                    if ($item.height() > maxHighestBox) {
                        maxHighestBox = $item.height();
                    }
                });
                handler.domNode.find('.gateway-item').height(maxHighestBox);

                let maxHightsDetailsBox = 0;
                handler.domNode.find('.gateway-detalis').each((index, item) => {
                    $item = $(item);
                    if ($(item).height() > maxHightsDetailsBox) {
                        maxHightsDetailsBox = $item.height();
                    }
                });
                handler.domNode.find('.gateway-detalis').height(maxHightsDetailsBox);
                me.gatewayItem = gatewayItem = handler.domNode.find('.gateway-items');

                gatewayItem.each((index, item) => {
                    $(item).on('click', function () {
                        $(item).next().click();
                        handler.domNode.find('.selected-gateway').removeClass('selected-gateway');
                        $(item).parent().parent().addClass('selected-gateway');
                        var requirements = $(this).data('requirements');
                        var works = $(this).data('works');
                        var requirementSection = handler.domNode.find('.gateway-requirements');
                        var worksSection = handler.domNode.find('.gateway-works');
                        requirementSection.empty();
                        worksSection.empty();
                        requirements.forEach(item => {
                            if (item != '' && item == 'uiCallBackUrl') {
                                requirementSection.append('<input type="hidden" name="uiCallBackUrl" value="' + location.href + '">');
                            } else if (item != '') {
                                requirementSection.append(view('partials/gateway-requirements', { item: item }));
                            }
                        })
                        works.forEach(item => {
                            if (item.type == 'view') {
                                worksSection.append(view(fileUrl(item.src)));
                            } else if (item.type == 'script') {
                                use(fileUrl(item.src));
                            }
                        })
                    })
                })

                $(gatewayItem[0]).click();
                me.walletItems = handler.domNode.find('.wallet-item-input');
                $(me.walletItems[0]).click();
                var $payForm = handler.domNode.find('.pay-form');
                var tracker = dataActive.getObject("PayForm", { payForm: $payForm, process: me, invoiceReferenceId: data.invoiceReferenceId, isManual: true });
                $payForm.find('[type=submit]').attr('type', 'button').on('click', function () {
                    me.payBtn = handler.domNode.find('.pay-button');
                    me.payBtn.text(trans('Pay_Start'));
                    me.payBtn.prop('disabled', true);
                    ajax(tracker.ajaxParams());
                    gatewayItem.each((index, item) => {
                        $(item).prop('disabled', true);
                    });
                });

            }

        });
    });
    let chargeBtn = handler.domNode.find('.wallet-charge');
    chargeBtn.click(function () {
        $(this).attr('disabled', true);
        ajax({
            url: dataActive.location.marketplace + "/v1/gateway/initial",
            method: 'POST',
            data: form.serialize(),
            headers: headers,
            success: success,
            error: error,

        });
    });

    function success(data) {
        if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
            me.getUserInfo();
        }
        if (!$.isEmptyObject(data.url)) {
            if (data.method == 'GET') {
                window.location.href = data.url;
                return;
            } else {
                $(view('partials/redirect-post', { data: data.data || {}, url: data.url })).appendTo('body')[0].submit();
            }
        } else if (!$.isEmptyObject(data.scriptSrc)) {
            use(fileUrl(data.scriptSrc));
        } else {
            swal({
                text: trans('Your e-wallet has been successfully charged.Please check your updated balance and transaction details.'),
                icon: "success",
            }).then(() => {
                dataActive.pageReload();
            });
        }
    }

    function error(data) {
        chargeBtn.attr('disabled', false);
        dataActive.defaultAjaxError(data);

    }

    this.getUserInfo = function () {
        var token = loginInformation.token;
        ajax({
            url: dataActive.location.marketplace + "/v1/user/info",
            method: 'get',
            data: {
                api_token: token,
            },
            headers: { 'Authorization': 'Bearer ' + token },
            success: function (data) {
                setStorage("loginInformation", data);
            },
            error: function () {
                console.log('We could not update the user information.');
            }
        });
    }

    this.getUserWallets = function () {
        var token = loginInformation.token;
        ajax({
            url: dataActive.location.marketplace + "/v1/user/wallets",
            method: 'get',
            headers: {'Authorization' : 'Bearer ' + token},
            success: function(data) {
                data.userWallets.forEach((wallet,walletIndex) => handler.domNode.find('.user-wallets-info').append(view('partials/user-wallets-information', {wallet , walletIndex})))
                let ewalletItems = handler.domNode.find('.ewallet-item');
                ewalletItems.click(function () {
                    let currency = ($(this)).data('currency');
                    handler.domNode.find('.selected-currency').text(currency.abb);
                    handler.domNode.find('.ewallet-currency').val(currency.id);
                });
                ewalletItems[0].click();
            }
        })
    }

    this.init = function () {
        if (!$.isEmptyObject(dataActive.location.query["paymentMsg"])) {
            swal({
                text: trans(decodeURI(dataActive.location.query["paymentMsg"])),
                dangerMode: true,
                icon: "error",
            });
        }
        if ($.isEmptyObject(dataActive.location.query["paymentMsg"]) && !$.isEmptyObject(dataActive.location.query["referenceId"])) {
            swal({
                text: trans(trans('Your e-wallet has been successfully charged.Please check your updated balance and transaction details.')),
                icon: "success",
            }).then(() => {
                dataActive.pageRoute("chargeEwalletInMyProfile")
            });
        }
        me.getUserWallets();
    }

    this.init();
} 