function PassengerInfoProcess(handler) {
    this.showUrl = dataActive.location.marketplace + "/v1/book/" + dataActive.location.query.referenceId + "/bookingInformation";
    this.bookingInformation;
    this.onHoldOrIssueBtn;
    this.gatewayItem;
    this.walletItems;
    this.payBtn;
    var me = this;
    var checkProgressTimerId;
    var maxCheckProgressTimerId;
    var invoiceReferenceId;
    var invoiceStatus;
    var bookValidationTimeInterval;
    var isProgressFinished = false;
    var delay = 3000;
    var waitTime = 30000;
    var loginInformation = getStorage("loginInformation");
    var data = {};
    var loading = handler.domNode.find('.loading');
    var pageContent = handler.domNode.find('.passenger-info-content');
    var serviceDetails = handler.domNode.find(".service-details");
    var reserveOrPaySection = handler.domNode.find('.reserve-pay-section');
    var gatewaySection = handler.domNode.find('.gateway-section');
    let onHoldOrIssueSection = handler.domNode.find('.onhold-issue');
    var roomTypeIndex = 0;
    if (!$.isEmptyObject(dataActive.location.query["gateway-session"])) {
        data.gatewaySession = dataActive.location.query["gateway-session"];
    }
    handler.domNode.find('.close-booklet').on('click', () => $('.search-previous-passengers').magnificPopup('close'));
    if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
        var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
    }
    this.showResult = function () {
        ajax({
            url: me.showUrl,
            method: "get",
            data: data,
            success: me.interpretationShowResult,
            headers: headers || {}
        });
    }

    ///> Check distance between deadLineTime and currentTime
    ///> only if we have time return remaining minutes and seconds
    ///> otherwise display a swal to search again or continue.
    this.checkTimeDistance = function (deadLine) {
        const currentTime = new Date().getTime();
        const ramainingTimeMS = deadLine - currentTime;
        const minuteSection = String(Math.floor(ramainingTimeMS / 60000)).padStart(2, '0');
        const secondSection = String(Math.trunc(Math.round(ramainingTimeMS % 60000) / 1000)).padStart(2, "0");
        if (Number(minuteSection) <= 0 && Number(secondSection) <= 0) {
            clearInterval(bookValidationTimeInterval);
            handler.domNode.find('.countdown-timer').text("00:00");
            handler.domNode.find('.countdown-timer').addClass('text-danger')
            if (dataActive.location.routeName == 'passengerInfo') {
                swal({
                    text: trans('The time allowed to complete the booking process has expired.Do you want to search again?'),
                    type: "info",
                    icon: "info",
                    buttons: ["NO", "YES"],
                }).then(isConfirm => {
                    if (isConfirm) {
                        me.searchAgain();
                    }
                })
            }
            return false;
        } else {
            return { minuteSection, secondSection };
        }
    }

    this.showCountDown = function (data) {
        const bookDeadLine = new Date(data.serviceInfo.searchValidity).getTime();
        bookValidationTimeInterval = setInterval(() => {
            const validBook = this.checkTimeDistance(bookDeadLine);
            if (validBook) {
                handler.domNode.find('.countdown-timer').text(`${validBook.minuteSection}:${validBook.secondSection}`);
            }

        }, 1000);
    }

    this.interpretationShowResult = function (data) {
        data = data.data;
        loading.hide();
        pageContent.show();
        me.bookingInformation = data;
        invoiceReferenceId = data.invoiceReferenceId;
        invoiceStatus = me.bookingInformation.invoiceStatus;
        var bookingStatus = me.bookingInformation.bookingStatus;
        switch (bookingStatus) {
            case 'initialized':
                me.mapContent(data);
                break;
            case 'validated':
                me.bookingIsValidated(data);
                break;
            case 'issued':
                me.bookingIsIssued(data);
                break;
            case 'failed':
                me.bookingIsFailed(data)
                break;
            case 'on_hold':
            case 'incomplete':
            case 'waiting_issue':
                me.bookingIsOnHoldOrWaitingIssue(data)
                break;
            case 'contact_admin':
                me.bookingHasError(data);
                break;
            default:
                break;
        }
    }

    this.bookingIsValidated = function (data) {
        handler.domNode.find(".submit-passenger").prop('disabled', true);
        switch (invoiceStatus) {
            case 'not_paid':
                if (!$.isEmptyObject(dataActive.location.query["gateway-session"])) {
                    swal({
                        text: trans('Error in payment process'),
                        dangerMode: true,
                        icon: "error",
                    });
                }
                if (!$.isEmptyObject(dataActive.location.query["paymentMsg"])) {
                    swal({
                        text: trans(decodeURI(dataActive.location.query["paymentMsg"])),
                        dangerMode: true,
                        icon: "error",
                    });
                }
                if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
                    me.checkOnholdable();
                } else {
                    handler.domNode.find('.sign-in-button').show();
                }
                me.mapContent(data);
                break;
            case 'paid':
                reserveOrPaySection.append(view("partials/show-gateway", { data: data }));
                me.showPassengerInformation(data);
                me.checkTheBook(data);
                break;
            case 'not_authorized':
                swal({
                    text: trans('This invoice is not payable.'),
                    dangerMode: true,
                    icon: "error",
                }).then(() => {
                    me.searchAgainOrRedirectToHomePage();
                });
                break;
            default:
                break;
        }
    }

    this.bookingIsIssued = function (data) {
        me.showPassengerInformation(data);
        handler.domNode.find(".submit-passenger").prop('disabled', true);
        reserveOrPaySection.append(view("partials/show-gateway", { data: data }));
        me.checkTheBook(data);
    }

    this.bookingIsFailed = function (data) {
        handler.domNode.find(".submit-passenger").prop('disabled', true);
        switch (invoiceStatus) {
            case 'paid':
                reserveOrPaySection.append(view("partials/show-gateway", { data: data }));
                me.showPassengerInformation(data);
                me.checkTheBook(data);
                break;
            case 'not_paid':
                reserveOrPaySection.append(view("partials/show-gateway", { data: data }));
                me.showPassengerInformation(data);
                swal({
                    text: 'Booking Failed.',
                    dangerMode: true,
                    icon: "error",
                }).then(() => {
                    me.searchAgainOrRedirectToHomePage();
                });
                break;
            default:
                break;
        }
    }

    this.bookingIsOnHoldOrWaitingIssue = function (data) {
        handler.domNode.find(".submit-passenger").prop('disabled', true);
        switch (invoiceStatus) {
            case 'not_paid':
                me.showPassengerInformation(data);
                me.pay(data);
                break;
            case 'paid':
                me.showPassengerInformation(data);
                reserveOrPaySection.append(view("partials/show-gateway", { data: data }));
                me.checkTheBook(data);
                break;

            case 'confirmed':
                me.showPassengerInformation(data);
                reserveOrPaySection.append(view("partials/show-gateway", { data: data }));
                me.checkTheBook(data);
                break;
            default:
                break;
        }
    }

    this.bookingHasError = function (data) {
        me.showPassengerInformation(data);
        handler.domNode.find(".submit-passenger").prop('disabled', true);
        reserveOrPaySection.append(view("partials/show-gateway", { data: data }));
        me.checkTheBook(data);
    }

    this.checkTheBook = function (data) {
        loading.show();
        checkProgressTimerId = setTimeout(me.checkProgress, 1000);
        // maxCheckProgressTimerId = setInterval(me.progressFinished, waitTime, data);
    }

    this.mapContent = function (data) {
        let travelService = data.travelService;
        data.optionals = []
        if (data.serviceInfo.searchValidity) {
            handler.domNode.find(".countdown-timer-wrapper").removeClass('d-none')
            me.showCountDown(data)
        }
        const passengerFormData = Object.values(data.passsengersForm)
        for (let passengerInfo of passengerFormData) {
            for (let [dataValue] of Object.values(passengerInfo)) {
                if (dataValue.optional == true) {
                    data.optionals.push(dataValue.caption)
                }
            }
        }

        switch (travelService) {
            case "hotel":
                me.hotelPassengerInformationForm(data, 'passengers-form');
                break;
            case "package":
                me.packagePassengerInformationForm(data, 'passengers-form');
                break;
            case "tour":
            case "event":
                me.tourAndEventPassengerInformationForm(data, 'passengers-form');
                break;
            case "flight":
                me.flightPassengerInformationForm(data, 'passengers-form');
                break;
            case "visa":
                me.visaPassengerInformationForm(data, 'passengers-form');
                break;
            default:
                break;
        }

        handler.domNode.find(".reference-id").val(data.bookingReferenceId)
        var $form = handler.domNode.find('.guestInfo');
        var tracker = dataActive.getObject("PassengerInfoForm", { form: $form, process: me });
        $form.find('[type=submit]').attr('type', 'button').on('click', function () {
            let hookAnswer = dataActive.hooks.call('beforeSubmit', { form: $form });
            if ($.isEmptyObject(hookAnswer) || $.isEmptyObject(hookAnswer.form)) {
                return;
            }
            $form = hookAnswer.form;
            $form.find(".book-button").prop('disabled', true);
            ajax(tracker.ajaxParams());
        });

        $form.find('.search-again-button').on('click', function () { dataActive.pageRoute("index"); });
        $form.find('.edit-passenger').on('click', function () {
            $(this).attr("disabled", true);
            $form.find('.passenger-info').attr("disabled", false);
            $form.find(".book-button").attr('disabled', false);
            $form.find('.img-pen').show();
            if ($form.find('.download-url').val() != "") {
                $form.find('.remove-picker').show();
            }
            onHoldOrIssueSection.empty();
            gatewaySection.empty();
        });

        var min_highestBox = 0;
        handler.domNode.find('.form-label').each((index, item) => {
            $item = $(item);
            if ($item.height() > min_highestBox) {
                min_highestBox = $item.height();
            }
        });
        handler.domNode.find('.form-label').height(min_highestBox);
        dataSelect2($form);
        picfile();
        let cuntriesPhoneCode = [];
        if (((!$.isEmptyObject(data.passsengersForm['all']['phone'])) || ((!$.isEmptyObject(data.passsengersForm['lead']) && !$.isEmptyObject(data.passsengersForm['lead']['phone']))))) {
            cuntriesPhoneCode = this.getCountryPhoneNumberCode();
        }
        var datePickerBirthday = handler.domNode.find('.datePickerBirthday');
        var datePickerPassportExpiry = handler.domNode.find('.datePickerPassportExpiry');
        new SpecialDate(datePickerBirthday, { language: dataActive.location.query.lang, dateType: 'birthdate' });
        new SpecialDate(datePickerPassportExpiry, { language: dataActive.location.query.lang, dateType: 'expirydate' });
        var passengerPhoneNumber = handler.domNode.find('.phone-number-with-code');
        new ParsePhoneNumber(passengerPhoneNumber, { codes: cuntriesPhoneCode });
        if (data.bookingStatus == "validated") {
            if ($form.find('.img-pen').length != 0) {
                $form.find('.img-pen').hide();
            }
            if ($form.find('.remove-picker').length != 0) {
                $form.find('.remove-picker').hide();
            }
            $form.find('.search-again-button').remove();
            $form.find('.passenger-info').attr("disabled", true);
            $form.find('.edit-passenger').attr('disabled', false).removeClass('d-none');
        }
        if (data.bookingStatus == "initialized") {
            if (data.forceCheckTerms) {
                handler.domNode.find('.terms-conditions-section').removeClass('d-none');
                let termsModal = handler.domNode.find('.terms-modal');
                handler.domNode.find('.terms-condition-link').click(function () {
                    let tcContent = termsModal.parent().find('.terms-condition-content');
                    tcContent.empty();
                    let viewName = travelService + '/terms-conditions';
                    tcContent.append(view(viewName, { data }));
                    termsModal.magnificPopup('open');
                });
                termsModal.magnificPopup({
                    type: 'inline',
                    fixedContentPos: true
                });

                let termsCheckbox = $form.find('#terms');
                let bookBtn = $form.find('.book-button');
                bookBtn.prop('disabled', !$form.find('#terms:checked').length);
                termsCheckbox.click(function () {
                    if ($form.find('#terms:checkbox:checked').length > 0) {
                        bookBtn.prop('disabled', false);
                    } else {
                        bookBtn.prop('disabled', true);
                    }
                });
            }
        }
        if ($(window).width() > 992) {
            $('.sticky-col').stick_in_parent({
                parent: $('#sticky-parent')
            });
        }
        datePickers();
    }
    this.getCountryPhoneNumberCode = function () {
        let codes = '';
        ajax({
            url: dataActive.location.marketplace + "/v1/getcountriesphonecode",
            method: "get",
            data: data,
            async: false,
            headers: headers || {},
            success: function (data) {
                codes = data;
            },
        })
        return codes;
    }

    this.hotelPassengerInformationForm = function (data, passengersForm) {
        handler.domNode.find('.section-title').text(trans('Enter_guest_details'));
        var selectedRoom = handler.domNode.find('.passengers-form-section');
        selectedRoom.empty();
        serviceDetails.append(view("hotel/room-summary-passenger-info", { data: data }));
        var passangerInfoSideBar = handler.domNode.find(".passenger-info-sidebar");
        var selectedRoomSummary = $('<div></div>');
        var roomNumber = data.queryParams.rooms_no;
        var passengersFormView = 'partials/' + passengersForm;
        var leadInfo = data.passsengersForm.lead || {};
        var allInfo = data.passsengersForm.all;
        var all = {};
        var lead = {};
        for (var i in leadInfo) {
            leadInfo[i].forEach((item) => {
                lead[item.caption] = item;
                lead[item.caption]['itemType'] = i
            });
        }
        for (var i in allInfo) {
            allInfo[i].forEach((item) => {
                all[item.caption] = item;
                all[item.caption]['itemType'] = i;
            });
        }
        if (!$.isEmptyObject(lead)) {
            var leadSection = handler.domNode.find('.lead-info-box');
            leadSection.show();
            leadSection.append(view(passengersFormView, { number: 0, type: "adult", guest: 0, schema: lead, data: data }));
        }
        var numberOfAdults = 0;
        var numberOfChildren = 0;
        for (var i = 0; i < roomNumber; i++) {
            selectedRoom.append(view('hotel/room-guest-information', { selectedRoomIndex: i, selectedRoom: data.serviceInfo.selectedCombination, roomNumber: roomNumber }));
            var adult = data.queryParams.rooms_detail[i]["adults"];
            var child = data.queryParams.rooms_detail[i]["children"];
            numberOfAdults += Number(data.queryParams.rooms_detail[i]["adults"]);
            numberOfChildren += Number(data.queryParams.rooms_detail[i]["children"]);
            selectedRoomSummary.first().append(view('hotel/room-summary-sidebar', { data: data, adult: adult, child: child, roomNumber: i }));
            var adultRow = $(selectedRoom.find('.room-guest-information')[i]).find('.adult');
            var childRow = $(selectedRoom.find('.room-guest-information')[i]).find('.child');
            if (adult != 0) {
                adultRow.prepend('<h4>' + trans('Adults') + '</h4>');
                for (var j = 0; j < adult; j++) {
                    adultRow.append(view(passengersFormView, { number: i, type: "adult", guest: j, schema: all, data: data }));
                }
            }
            if (child != 0) {
                childRow.prepend(`<h4>${trans('Children')}</h4><div class="text-danger error-text-static error-notice" data-class="rooms[${i}]"></div>`);
                for (var j = 0; j < child; j++) {
                    childRow.append(view(passengersFormView, { number: i, type: "child", guest: j, schema: all, data: data }));
                }

            }
        };
        if ((data.bookingStatus == "validated" || data.bookingStatus == "initialized") && (!$.isEmptyObject(loginInformation) && loginInformation.token !== "")) {
            me.previousPassengers(data);
        }

        passangerInfoSideBar.append(view("hotel/passenger-info-sidebar", { data: data, selectedRoomSummary: selectedRoomSummary[0].innerHTML, numberOfAdults: numberOfAdults, numberOfChildren: numberOfChildren }));
    }

    this.flightPassengerInformationForm = function (data, passengersForm) {
        handler.domNode.find('.section-title').text(trans('Enter_passenger_details'));
        if (passengersForm == 'flight-passengers') {
            handler.domNode.find('.passenger-form-notice').append(`<div class="user-notice"><p><strong> ${trans('Note')} : </strong>${trans('passenger_form_notice')}</p></div>`);
        }
        if (data.priceInfo.changePrice) {
            let priceChangedSection = handler.domNode.find('.flight-price-changed');
            priceChangedSection.removeClass('d-none');
            let currency = data.queryParams.marketplace_currency;
            let oldPrice = data.priceInfo.oldPrice;
            let price = data.invoiceAmount;
            priceChangedSection.append(view('flight/change-price', { data, oldPrice, price, currency: currency.abb }));
            handler.domNode.find('.flight-serach-again').click(function () {
                let filghtSearch = getStorage('ajaxParams');
                filghtSearch.data['searchAgain'] = true;
                filghtSearch['success'] = function (data) {
                    let sessionId = data.sessionId || "";
                    if (String(sessionId).length > 0) {
                        dataActive.pageRoute("flightResult", data);
                    }
                }
                ajax(filghtSearch);
            })
        }
        $view = $(view("flight/flight-detail-passenger-info", { data: data }));
        if(data.queryParams.tripType == 'openReturn') {
            $view.find('.leg-details').append(view('flight/leg-details', { leg : data.serviceInfo.legs[0] }));
            $view.find('.routes').append(`<div><h5 class="theme-search-results-item-title d-inline-block">${data.serviceInfo.legs[0].info.departure.city}, ${data.serviceInfo.legs[0].info.departure.airport.abb} - ${data.serviceInfo.legs[0].info.arrival.city}, ${data.serviceInfo.legs[0].info.arrival.airport.abb}</h5><span> - ${trans('Depart')}:${DateFormat(data.serviceInfo.legs[0].info.departure.date_time, 'MDY')}</span></div>`);
        }
        else {
            data.serviceInfo.legs.forEach((leg, legIndex) => {
                $view.find('.leg-details').append(view('flight/leg-details', { leg }));
                $view.find('.routes').append(`<div><h5 class="theme-search-results-item-title d-inline-block">${leg.info.departure.city}, ${leg.info.departure.airport.abb} - ${leg.info.arrival.city}, ${leg.info.arrival.airport.abb}</h5><span> - ${trans('Depart')}:${DateFormat(leg.info.departure.date_time, 'MDY')}</span></div>`);
            })
        }
        
        serviceDetails.append($view);
        var passangerInfoSideBar = handler.domNode.find(".passenger-info-sidebar");
        passangerInfoSideBar.append(view("flight/flight-summary-passenger-info-sidebar", { data: data, loginInformation }));
        var AdultGuestRow = handler.domNode.find(".adult-guest-row");
        var childGuestRow = handler.domNode.find(".child-guest-row");
        var infantGuestRow = handler.domNode.find(".infant-guest-row");
        var adults = data.serviceInfo.passengersCount.adults;
        var children = data.serviceInfo.passengersCount.children;
        var infants = data.serviceInfo.passengersCount.infants;
        var passengersFormView = 'partials/' + passengersForm;
        var leadInfo = data.passsengersForm.lead || {};
        var allInfo = data.passsengersForm.all;
        var all = {};
        var lead = {};
        for (var i in leadInfo) {
            leadInfo[i].forEach((item) => {
                lead[item.caption] = item;
                lead[item.caption]['itemType'] = i
            });
        }
        for (var i in allInfo) {
            allInfo[i].forEach((item) => {
                all[item.caption] = item;
                all[item.caption]['itemType'] = i;
            });
        }
        if (!$.isEmptyObject(lead)) {
            var leadSection = handler.domNode.find('.lead-info-box');
            leadSection.show();
            leadSection.append(view(passengersFormView, { number: 0, type: "adults", schema: lead, data: data }));
        }
        if (adults != 0) {
            AdultGuestRow.prepend('<h4>' + trans('Adults') + '</h4>');
            for (var i = 0; i < adults; i++) {
                AdultGuestRow.append(view(passengersFormView, { number: i, type: "adults", schema: all, data: data }));
            }
        }
        if (children != 0) {
            childGuestRow.show();
            childGuestRow.prepend('<h4>' + trans('Children') + '</h4>');
            for (var i = 0; i < children; i++) {
                childGuestRow.append(view(passengersFormView, { number: i, type: "children", schema: all, data: data }));
            }
        }
        if (infants != 0) {
            infantGuestRow.show();
            infantGuestRow.prepend('<h4>' + trans('Infants') + '</h4>');
            for (var i = 0; i < infants; i++) {
                infantGuestRow.append(view(passengersFormView, { number: i, type: "infants", schema: all, data: data }));
            }
        }
        if ((data.bookingStatus == "validated" || data.bookingStatus == "initialized") && (!$.isEmptyObject(loginInformation) && loginInformation.token !== "")) {
            me.previousPassengers(data);
        }
        if (passengersForm == 'passengers-form' && data.passsengersForm['captcha'] !== undefined) {
            handler.domNode.find('.get-captcha').append(view('partials/captcha', { data }))
        }
        let onChangeNatinality = handler.domNode.find('.onchange-natinality');
        if (onChangeNatinality.length > 0) {
            onChangeNatinality.on('change', function () {
                let $element = $(this);
                passengerBox = $element.closest('.passenger-info-box');
                passengerBox.find(`input[itemtype="nationalId"]`).parent().parent().addClass('d-none');
                passengerBox.find(`select[itemtype="passport_country"]`).parent().parent().parent().addClass('d-none');
                passengerBox.find(`input[itemtype="passport_number"]`).parent().parent().addClass('d-none');
                passengerBox.find(`input[itemtype="passport_expiry"]`).parent().parent().parent().addClass('d-none');
                if ($element.val() != null) {
                    if ($element.val() == 105) {
                        passengerBox.find(`input[itemtype="nationalId"]`).parent().parent().removeClass('d-none');
                    } else {
                        passengerBox.find(`select[itemtype="passport_country"]`).parent().parent().parent().removeClass('d-none');
                        passengerBox.find(`input[itemtype="passport_number"]`).parent().parent().removeClass('d-none');
                        passengerBox.find(`input[itemtype="passport_expiry"]`).parent().parent().removeClass('d-none');
                        passengerBox.find(`input[itemtype="passport_expiry"]`).parent().parent().parent().removeClass('d-none');
                    }
                }
            })
        }
        if (onChangeNatinality.length > 0) {
            onChangeNatinality.change();
        }
        handler.domNode.find('.otp-inputs-section input').each((index,otpInput) => {
            $(otpInput).on('input',() => {
                if(index < 5)
                    $(otpInput).next().focus();
                else 
                    $(otpInput).blur();
            })
        });
    }

    this.visaPassengerInformationForm = function (data, passengersForm) {
        handler.domNode.find('.section-title').text(trans('Enter_passenger_details'));
        var passangerInfoSideBar = handler.domNode.find(".passenger-info-sidebar");
        passangerInfoSideBar.append(view("visa/visa-summary-passenger-info-sidebar", { data: data }));
        var AdultGuestRow = handler.domNode.find(".adult-guest-row");
        var travelers = data.serviceInfo.passengersCount;
        var passengersFormView = 'partials/' + passengersForm;
        var leadInfo = data.passsengersForm.lead || {};
        var allInfo = data.passsengersForm.all;
        var all = {};
        var lead = {};
        for (var i in leadInfo) {
            leadInfo[i].forEach((item) => {
                lead[item.caption] = item;
                lead[item.caption]['itemType'] = i;
            });
        }
        for (var i in allInfo) {
            allInfo[i].forEach((item) => {
                all[item.caption] = item;
                all[item.caption]['itemType'] = i;
            });
        }
        if (!$.isEmptyObject(lead)) {
            var leadSection = handler.domNode.find('.lead-info-box');
            leadSection.show();
            leadSection.append(view(passengersFormView, { number: 0, schema: lead, data: data }));
        }

        AdultGuestRow.prepend('<h4>' + trans('Travelers') + '</h4>');
        for (var i = 0; i < travelers; i++) {
            AdultGuestRow.append(view(passengersFormView, { number: i, schema: all, data: data }));
        }

        if ((data.bookingStatus == "validated" || data.bookingStatus == "initialized") && (!$.isEmptyObject(loginInformation) && loginInformation.token !== "")) {
            me.previousPassengers(data);
        }
    }

    this.tourAndEventPassengerInformationForm = function (data, passengersForm) {
        handler.domNode.find('.section-title').text(trans('Enter_passenger_details'));
        serviceDetails.append(view("partials/package-tour-event-detail", { data: data }));
        var passangerInfoSideBar = handler.domNode.find(".passenger-info-sidebar");
        passangerInfoSideBar.append(view('partials/package-tour-event-passengerinfo-sidebar', { data: data }));
        var AdultGuestRow = handler.domNode.find(".adult-guest-row");
        var childGuestRow = handler.domNode.find(".child-guest-row");
        var infantGuestRow = handler.domNode.find(".infant-guest-row");
        var adults = data.serviceInfo.passengersCount.adults;
        var children = data.serviceInfo.passengersCount.children;
        var infants = data.serviceInfo.passengersCount.infants;
        var passengersFormView = 'partials/' + passengersForm;
        var leadInfo = data.passsengersForm.lead || {};
        var allInfo = data.passsengersForm.all;
        var all = {};
        var lead = {};
        for (var i in leadInfo) {
            leadInfo[i].forEach((item) => {
                lead[item.caption] = item;
                lead[item.caption]['itemType'] = i
            });
        }
        for (var i in allInfo) {
            allInfo[i].forEach((item) => {
                all[item.caption] = item;
                all[item.caption]['itemType'] = i;
            });
        }
        if (!$.isEmptyObject(lead)) {
            var leadSection = handler.domNode.find('.lead-info-box');
            leadSection.show();
            leadSection.append(view(passengersFormView, { number: 0, type: "adults", schema: lead, data: data }));
        }
        if (adults != 0) {
            AdultGuestRow.prepend('<h4>' + trans('Adults') + '</h4>');
            for (var i = 0; i < adults; i++) {
                AdultGuestRow.append(view(passengersFormView, { number: i, type: "adults", schema: all, data: data }));
            }
        }
        if (children != 0) {
            childGuestRow.show();
            childGuestRow.prepend('<h4>' + trans('Children') + '</h4>');
            for (var i = 0; i < children; i++) {
                childGuestRow.append(view(passengersFormView, { number: i, type: "children", schema: all, data: data }));
            }
        }
        if (infants != 0) {
            infantGuestRow.show();
            infantGuestRow.prepend('<h4>' + trans('Infants') + '</h4>');
            for (var i = 0; i < infants; i++) {
                infantGuestRow.append(view(passengersFormView, { number: i, type: "infants", schema: all, data: data }));
            }
        }

        if ((data.bookingStatus == "validated" || data.bookingStatus == "initialized") && (!$.isEmptyObject(loginInformation) && loginInformation.token !== "")) {
            me.previousPassengers(data);
        }
    }

    this.packagePassengerInformationForm = function (data, passengersForm) {
        handler.domNode.find('.section-title').text(trans('Enter_passenger_details'));
        serviceDetails.append(view("package/package-details", { data: data, lang: dataActive.location.query.lang }));
        var passengerFormSection = handler.domNode.find('.passengers-form-section');
        passengerFormSection.empty();
        var passangerInfoSideBar = handler.domNode.find(".passenger-info-sidebar");
        passangerInfoSideBar.append(view('package/passengerinfo-sidebar', { data: data, lang: dataActive.location.query.lang }));
        var roomTypes = data.serviceInfo['passengersCount'];
        var passengersFormView = 'partials/' + passengersForm;
        var leadInfo = data.passsengersForm.lead || {};
        var allInfo = data.passsengersForm.all;
        var all = {};
        var lead = {};
        for (var i in leadInfo) {
            leadInfo[i].forEach((item) => {
                lead[item.caption] = item;
                lead[item.caption]['itemType'] = i
            });
        }
        for (var i in allInfo) {
            allInfo[i].forEach((item) => {
                all[item.caption] = item;
                all[item.caption]['itemType'] = i;
            });
        }
        if (!$.isEmptyObject(lead)) {
            var leadSection = passengerFormSection.prev();
            leadSection.show();
            leadSection.append(view(passengersFormView, { number: 0, type: "adults", schema: lead, data: data, roomType: Object.keys(roomTypes)[0], numberOfRoom: 0 }));
        }
        for (var roomType in roomTypes) {
            var packageRooms = $(view('package/passenger-form-section', { roomType: roomType }));
            passengerFormSection.append(packageRooms);
            roomTypes[roomType].forEach((item, index) => {
                passangerInfoSideBar.find('.selected-room-detail').append(view('package/room-detail-in-sidebar', { roomType: roomType, item: item, indexOfRoom: index + 1 }));
                packageRooms.find('.rooms').append(`<div><div class="fs-16 fw-600 _mb-10">${trans('Room')} ${index + 1} :</div><div class="adult"></div><div class="child"></div><div class="child-wo-bed"></div><div class="infant"></div></div>`);
                var $dultSection = $((packageRooms.find('.adult'))[index]);
                var $childSection = $((packageRooms.find('.child'))[index]);
                var $childWOBedSection = $((packageRooms.find('.child-wo-bed'))[index]);
                var $infantSection = $((packageRooms.find('.infant'))[index]);
                if (item.adults != 0) {
                    $dultSection.prepend(`<div class="fw-bold">${trans('Adults')}</div>`);
                    for (let i = 0; i < item.adults; i++) {
                        $dultSection.append(view(passengersFormView, { number: i, type: "adults", schema: all, data: data, roomType: roomType, numberOfRoom: index }));
                    }
                }
                if (item.children != 0) {
                    $childSection.prepend(`<div class="fw-bold">${trans('Children')}</div>`);
                    for (let i = 0; i < item.children; i++) {
                        $childSection.append(view(passengersFormView, { number: i, type: "children", schema: all, data: data, roomType: roomType, numberOfRoom: index }));
                    }
                }
                if (item.childrenWOBed != 0) {
                    $childWOBedSection.prepend(`<div class="fw-bold">${trans('Children w/o bed')}</div>`);
                    for (let i = 0; i < item.childrenWOBed; i++) {
                        $childWOBedSection.append(view(passengersFormView, { number: i, type: "childrenWOBed", schema: all, data: data, roomType: roomType, numberOfRoom: index }));
                    }
                }
                if (item.infants != 0) {
                    $infantSection.prepend(`<div class="fw-bold">${trans('Infants')}</div>`);
                    for (let i = 0; i < item.infants; i++) {
                        $infantSection.append(view(passengersFormView, { number: i, type: "infants", schema: all, data: data, roomType: roomType, numberOfRoom: index }));
                    }
                }

            });
            roomTypeIndex++;
        }

        if ((data.bookingStatus == "validated" || data.bookingStatus == "initialized") && (!$.isEmptyObject(loginInformation) && loginInformation.token !== "")) {
            me.previousPassengers(data);
        }
    }

    this.loadDataTableCss = () => {
        const dataTableStyles = document.createElement('link');
        dataTableStyles.href = "https://cdn.infra.luxota.cloud/assets/css/datatables.min.css";
        dataTableStyles.rel = 'stylesheet'
        document.head.appendChild(dataTableStyles)
    }

    this.previousPassengers = function (data) {
        handler.domNode.find('.passenger-info-box');
        handler.domNode.find('.passenger-info-box').each((index, item) => {
            let $item = $(item);
            $item.prepend(`<div><button type="button" class="btn btn-primary load-previous-passengers"> ${trans('Load_from_previous_saved_names')} </button></div>`);
            $item.find('.load-previous-passengers').click(() => {
                if (!$("[href*='datatables.min.css']").length) {
                    this.loadDataTableCss()
                }
                var script = document.createElement('script');
                script.setAttribute('src', "https://cdn.infra.luxota.cloud/assets/js/datatable.js");
                script.onload = function (e) {
                    me.getPreviousPassengers(data, $item);
                };
                document.body.append(script);
                script.remove();
            });
        });
        handler.domNode.find('.search-previous-passengers a').magnificPopup({
            type: 'inline',
            fixedContentPos: true
        });
    }

    this.getPreviousPassengers = function (passengerData, passengerBox) {
        let referenceId = passengerData.bookingReferenceId;
        let searchPreviousPassengersModal = handler.domNode.find('.search-previous-passengers');
        searchPreviousPassengersModal.find('a').magnificPopup('open');
        searchPreviousPassengersModal.find('.search-previous-passengers-content').empty();
        searchPreviousPassengersModal.find('.search-previous-passengers-content').html(view('partials/get-previous-passengers'));
        let resultContent = searchPreviousPassengersModal.find('#result-content');
        const resultTable = resultContent.DataTable({
            ajax: {
                url: `${dataActive.location.marketplace}/v1/getpreviouspassengers`,
                headers: {
                    'Authorization': 'Bearer ' + loginInformation.token,
                },
                dataSrc: "passengers"
            },
            language: {
                "paginate": {
                    "previous": '<i class="fa fa-angle-left" style="color: #000"></i>',
                    "next": '<i class="fa fa-angle-right" style="color: #000"></i>'
                }
            },
            bInfo: false,
            dom: '<f<t>lp><"clear">',
            responsive: true,
            autoFill: true,
            columns: [
                { "data": 'details.first_name.0.value', "defaultContent": "-" },
                { "data": 'details.last_name.0.value', "defaultContent": "-" },
                { "data": 'details.birthdate.0.value', "defaultContent": "-" },
                { "data": 'details.gender.0.value', "defaultContent": "-" },
                { "data": 'details.nationality.0.countryinfo.en', "defaultContent": "-" },
                { "data": 'details.nationalId.0.value', "defaultContent": "-" },
                { "data": 'details.passport_country.0.countryinfo.en', "defaultContent": "-" },
                { "data": 'details.passport_number.0.value', "defaultContent": "-" },
                { "data": 'details.passport_expiry.0.value', "defaultContent": "-" },
            ],
        });

        $('#result-content tbody').on('click', 'tr', function () {
            $(this).addClass('selected');
            var data = resultTable.rows('.selected').data();
            let passengerInfo = data[0]['details'];
            me.mapPassengersInfos(passengerInfo, passengerBox);
            $('.search-previous-passengers').magnificPopup('close');
        });
    }

    this.mapPassengersInfos = function (passengerInfo, passengerBox) {
        for (const type in passengerInfo) {
            for (const key in passengerInfo[type]) {
                let formItem = passengerInfo[type][key];
                if (formItem['itemType'] == 'specific') {
                    let element = passengerBox.find(`*[itemtype="${type}"]`);
                    element.val(formItem['value']);
                    if (element.prop('nodeName') == 'SELECT' && element.hasClass('select2')) {
                        element.append(`<option value="${formItem['countryinfo']['id']}" title="${formItem['countryinfo']['title']} - ${formItem['countryinfo']['abb']}">${formItem['countryinfo']['title']} - ${formItem['countryinfo']['abb']}</option>`);
                    }
                    if (element.hasClass('datePickerBirthday') || element.hasClass('datePickerPassportExpiry')) {
                        if (element.val() != '') {
                            let date = (element.val()).split('-');
                            element.parent().find('.day').val(parseInt(date[2], 10));
                            element.parent().find('.month').val(parseInt(date[1], 10));
                            element.parent().find('.year').val(date[0]);
                        }
                    }
                } else {
                    let element = passengerBox.find('[name*="[' + formItem['caption'] + ']"]');
                    element.val(formItem['value']);
                    if (element.hasClass('download-url')) {
                        element.parent().prev().attr('src', fileUrl(element.val()));
                    }
                    if (element.prop('nodeName') == 'SELECT' && element.hasClass('select2')) {
                        element.append(`<option value="${formItem['countryinfo']['id']}" title="${formItem['countryinfo']['title']} - ${formItem['countryinfo']['abb']}">${formItem['countryinfo']['title']} - ${formItem['countryinfo']['abb']}</option>`);
                    }
                    if (formItem['type'] == 'phone') {
                        $(element[0]).val(formItem['value']['countryPhoneCode']);
                        $(element[1]).val(formItem['value']['phone']);
                    }
                }
            }
        }
    }

    this.showPassengerInformation = function (data) {

        switch (data.travelService) {
            case "hotel":
                me.hotelPassengerInformationForm(data, 'passenger-information');
                break;
            case "package":
                me.packagePassengerInformationForm(data, 'passengers-information');
                break;
            case "tour":
            case "event":
                me.tourAndEventPassengerInformationForm(data, 'passengers-information');
                break;
            case "flight":
                me.flightPassengerInformationForm(data, 'passengers-information');
                break;
            case "visa":
                me.visaPassengerInformationForm(data, 'passengers-information');
                break;
            default:
                break;
        }
        handler.domNode.find(".reference-id").val(data.bookingReferenceId);
        handler.domNode.find('.edit-passenger').prop("disabled", true);
    }

    this.pay = function () {
        loading.show();
        me.onHoldOrIssueBtn = onHoldOrIssueBtn = handler.domNode.find('.book-type');
        var invoiceInformation;
        invoiceInformation = getGateways(invoiceReferenceId);
        var allGateways = invoiceInformation[0][0];
        var ewalletWoMPC = invoiceInformation[0][1];
        var existGatewayWoMPC = ((!$.isEmptyObject(invoiceInformation[0][1])) && (!$.isEmptyObject(invoiceInformation[0][0]))) ? true : false;
        gatewaySection.html(view("partials/pay-section", { invoiceInformation, allGateways, ewalletWoMPC, existGatewayWoMPC }));
        handler.domNode.find('.show-extra-gateway').on('click', function () {
            if ((handler.domNode.find('.show-extra-gateway')).is(":checked")) {
                $(this).parent().next().removeClass('d-none');
            } else {
                $(this).parent().next().addClass('d-none');
            }
        });

        let maxHighestBox = 0;
        handler.domNode.find('.gateway-item').each((index, item) => {
            $item = $(item);
            if ($item.height() > maxHighestBox) {
                maxHighestBox = $item.height();
            }
        });
        handler.domNode.find('.gateway-item').height(maxHighestBox);

        let maxHightsDetailsBox = 0;
        handler.domNode.find('.gateway-detalis').each((index, item) => {
            $item = $(item);
            if ($(item).height() > maxHightsDetailsBox) {
                maxHightsDetailsBox = $item.height();
            }
        });
        handler.domNode.find('.gateway-detalis').height(maxHightsDetailsBox);

        me.gatewayItem = gatewayItem = handler.domNode.find('.gateway-items');
        gatewayItem.each((index, item) => {
            $(item).on('click', function () {
                $(item).next().click();
                handler.domNode.find('.selected-gateway').removeClass('selected-gateway');
                $(item).parent().parent().addClass('selected-gateway');
                var requirements = $(this).data('requirements');
                var works = $(this).data('works');
                var requirementSection = handler.domNode.find('.gateway-requirements');
                var worksSection = handler.domNode.find('.gateway-works');
                requirementSection.empty();
                worksSection.empty();
                requirements.forEach(item => {
                    if (item != '' && item == 'uiCallBackUrl') {
                        requirementSection.append('<input type="hidden" name="uiCallBackUrl" value="' + location.href + '">');
                    } else if (item != '') {
                        requirementSection.append(view('partials/gateway-requirements', { item: item }));
                    }
                })
                works.forEach(item => {
                    if (item.type == 'view') {
                        worksSection.append(view(fileUrl(item.src)));
                    } else if (item.type == 'script') {
                        use(fileUrl(item.src));
                    }
                })
            })
        })

        $(gatewayItem[0]).click();
        me.walletItems = handler.domNode.find('.wallet-item-input');
        $(me.walletItems[0]).click();
        var $payForm = handler.domNode.find('.pay-form');
        $(".invoice-reference-id").val(invoiceReferenceId);
        var tracker = dataActive.getObject("PayForm", { payForm: $payForm, process: me, invoiceReferenceId: invoiceReferenceId });
        $payForm.find('[type=submit]').attr('type', 'button').on('click', function () {
            clearInterval(bookValidationTimeInterval);
            onHoldOrIssueBtn.each((index, item) => {
                $(item).prop('disabled', true);
            });
            me.payBtn = handler.domNode.find('.pay-button');
            me.payBtn.text(trans('Pay_Start'));
            me.payBtn.addClass('spinner');
            me.payBtn.prop('disabled', true);
            ajax(tracker.ajaxParams());
            gatewayItem.each((index, item) => {
                $(item).prop('disabled', true);
            });
        });
    }

    this.chooseOnholdOrIssue = function () {
        onHoldOrIssueSection.append(view('partials/onhold-issue-section'));
        var onHoldOrIssueBtn = handler.domNode.find('.book-type');
        var reserveButton = handler.domNode.find('.reserve-now-button');
        onHoldOrIssueBtn.each((index, item) => {
            $(item).on('click', function () {
                if ($(this).val() == 1) {
                    reserveButton.show();
                    gatewaySection.hide();
                } else {
                    reserveButton.hide();
                    gatewaySection.show();
                    me.pay();
                }
            })
        })
        onHoldOrIssueBtn[0].click();
        reserveButton.on('click', function () {
            onHoldOrIssueBtn.each((index, item) => {
                $(item).prop('disabled', true);
            });
            reserveButton.text(trans('Pay_Start'));
            reserveButton.prop('disabled', true);
            loading.show();
            if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
                var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
            }
            ajax({
                url: dataActive.location.marketplace + "/v1/book-only/" + invoiceReferenceId,
                headers: headers || {},
                method: 'get',
                success: function (data) {
                    var referenceId = $(".reference-id").val();
                    if (data.status == true) {
                        me.redirectToVoucherpage(referenceId);
                    }
                },
                error: function (data) {
                    swal({
                        text: 'Booking Failed.',
                        dangerMode: true,
                        icon: "error",
                    }).then(() => {
                        me.searchAgainOrRedirectToHomePage();
                    });
                }

            })
        });
    }

    this.checkProgress = function (data) {
        if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
            var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
        }
        dataActive.hooks.clear('afterAjaxError');
        ajax({
            url: dataActive.location.marketplace + "/v1/invoice/check-progress/" + invoiceReferenceId,
            method: "get",
            headers: headers || {},
            data: {},
            success: function (data) {
                var referenceId = $(".reference-id").val();
                if (data.percent != 100) {
                    checkProgressTimerId = setTimeout(me.checkProgress, delay, data);
                    return;
                };
                isProgressFinished = true;
                // me.progressFinished();
                me.redirectToVoucherpage(referenceId);
            },
            error: function (data) {
                // lug.error("check progress has error", { message: data });
                me.kill();
                var errorMessage = (data.responseJSON.message != undefined) ? data.responseJSON.message : 'Error Happend';
                swal({
                    text: trans(errorMessage),
                    dangerMode: true,
                    icon: "error",
                }).then(() => {
                    me.searchAgainOrRedirectToHomePage();
                });
            }
        });
    }

    this.searchAgain = function () {
        const serviceType = dataActive.location.query.type
        let params = getStorage('ajaxParams');
        params['success'] = function (data) {
            let sessionId = data.sessionId || "";
            // lug.info("sessionId", { message: data });
            if (String(sessionId).length > 0) {
                dataActive.pageRoute(`${serviceType}Result`, data);
            } else {
                // lug.error(`Error happend in serach ${serviceType}`, { message: data });
                dataActive.pageRoute("index");
            }
        };
        params['error'] = function (data) {
            // lug.error(`Error happend in serach ${serviceType}`, { message: data });
            dataActive.pageRoute("index");
        }
        ajax(params);
    }

    this.searchAgainOrRedirectToHomePage = function () {
        if (dataActive.location.query.type == 'flight') {
            let filghtSearch = getStorage('ajaxParams');
            filghtSearch['success'] = function (data) {
                let sessionId = data.sessionId || "";
                // lug.info("sessionId", { message: data });
                if (String(sessionId).length > 0) {
                    dataActive.pageRoute("flightResult", data);
                } else {
                    // lug.error("Error happend in serach flight", { message: data });
                    dataActive.pageRoute("index");
                }
            };
            filghtSearch['error'] = function (data) {
                // lug.error("Error happend in serach flight", { message: data });
                dataActive.pageRoute("index");
            }
            ajax(filghtSearch);
        } else {
            dataActive.pageRoute("index");
        }
    }

    this.redirectToVoucherpage = function (referenceId) {
        dataActive.pageRoute(dataActive.location.query.type + 'Voucher', { lang: dataActive.location.query.lang, referenceId: referenceId });
    }

    // this.progressFinished = function () {
    //     if (isProgressFinished == false) {
    //         swal({
    //             text: trans('Processing, Please be patient.'),
    //             dangerMode: true,
    //             icon: "error",
    //         })
    //     }
    // }

    function dataSelect2($form) {
        $form.find(".select2").each(function (index, select) {
            var $select = $(select);
            var placeholder = $select.data("placeholder");
            $($select).select2({
                ajax: {
                    url: dataActive.location.marketplace + $select.data("url") + '?lang=' + dataActive.location.query.lang.toLowerCase(),
                    dataType: 'json',
                    data: function (params) {
                        return {
                            q: params.term, // search term
                        };
                    },
                    processResults: function (data, params) {
                        return {
                            results: data.data
                        };
                    },
                    cache: false,
                    delay: 1000,
                },
                escapeMarkup: (markup) => markup,

                minimumInputLength: 3,
                language: {
                    inputTooShort: function () {
                        return trans('Enter 3 or more characters');
                    }
                },
                allowClear: true,
                placeholder: placeholder,
                templateResult: (item) => item.title ? `<option value="${item.id}">${item.title}</option>` : '',
                templateSelection: (item) => item.title || placeholder,

            });
        });
    }

    function getGateways(invoiceReference) {
        var invoiceData = [];
        if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
            var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
        }
        dataActive.hooks.clear('afterAjaxError');
        ajax({
            url: dataActive.location.marketplace + "/v1/gateway",
            headers: headers || {},
            method: "get",
            data: { referenceId: invoiceReference },
            async: false,
            success: function (data) {
                loading.hide();
                invoiceData[0] = data.gateways;
                invoiceData[1] = data.invoiceAmount;
                invoiceData[2] = data.marketplaceCurrency;
            },
        });
        return invoiceData;
    }

    this.kill = function () {
        clearInterval(bookValidationTimeInterval)
        clearInterval(checkProgressTimerId);
        clearInterval(maxCheckProgressTimerId);
    }

    this.checkOnholdable = function () {
        if (me.bookingInformation.onholdable == 1) {
            me.chooseOnholdOrIssue();
        } else {
            me.pay();
        }
    }

    function picfile() {
        var travelerPhoto = handler.domNode.find(".traveler-photo");
        travelerPhoto.each((index, item) => {
            $file = $(item);
            new FilePicker($file, { target: 'travelerPhoto' });
            if (!$.isEmptyObject($file[0])) {
                $file[0].filePicker.load($file.data("value"));
            }
        });
    }

    this.init = function () {
        this.showResult();
        dataActive.hooks.register('changeCurrency', function (carrency) {
            if (currencyChangeAlert()) {
                return carrency;
            }
        });
        dataActive.hooks.register('changeLanguage', function (language) {
            if (languageChangeAlert()) {
                return language;
            }
        });
        dataActive.hooks.register('beforeSubmit', function (configs) {
            configs.form = queueable(configs.form);

            if (configs.form.isBusy == undefined || !configs.form.isBusy()) {
                return configs;
            }
            swal({
                text: trans('some images are uploading'),
                dangerMode: true,
                icon: "error",
            });
        });

        dataActive.hooks.register('beforePageRoute', function (configs) {
            me.kill();
            return configs;
        });
    }
    this.init();
}