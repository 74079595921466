function VisaResultProcess(handler) {
    this.progressUrl = dataActive.location.marketplace + "/v1/search/progress";
    this.resultUrl = dataActive.location.marketplace + "/v1/search/results";
    var intervalId;
    var isProgressFinished = false;
    var delay = 3000;
    var me = this;
    var waitTime = 60000;
    var $resultItem = handler.domNode.find(".visa-item");
    var $moreResult = handler.domNode.find(".more-result");
    var $waiting = handler.domNode.find(".search-loader");
    var $filters = handler.domNode.find(".visa-filters");
    var $filtersForm;
    var $pageNumber;
    var messageCode = "t<wt";
    var searchBtn = handler.domNode.prev().find('.service-search-btn');
    var headers = {};
    var loginInformation = getStorage("loginInformation");
    if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
       headers = { 'Authorization': 'Bearer ' + loginInformation.token };
    }

    searchBtn.prop('disabled', true);
    dataActive.hooks.register('afterAjaxError', this.afterAjaxError);

    this.checkProgress = function () {
        ajax({
            url: me.progressUrl,
            method: "get",
            data: {
                sessionId: dataActive.location.query.sessionId
            },
            headers: headers || {},
            success: function (data) {
                if (data.percent == 100) {
                    me.searchResult(messageCode + ",p=100");
                    isProgressFinished = true;
                }
            },
        });
    }

    this.progressFinished = function () {
        messageCode = "t>wt";
        if (isProgressFinished == false) {
            ajax({
                url: me.progressUrl,
                method: "get",
                data: {
                    sessionId: dataActive.location.query.sessionId
                },
                headers: headers || {},
                success: function (data) {
                    if (data.percent == 0) {
                        me.manageResults(messageCode + ",p=0")
                    } else if (data.percent < 100) {
                        me.searchResult(messageCode + ",p<100");
                    } else {
                        me.searchResult(messageCode + ",p=100");
                    }
                },
            });
        }
    }

    this.searchResult = function (latestMessageCode) {
        ajax({
            url: me.resultUrl,
            method: "get",
            data: {
                sessionId: dataActive.location.query.sessionId
            },
            headers: headers || {},
            success: function (data) {
                searchBtn.prop('disabled', false);
                if (data.data.length == 0) {
                    me.manageResults(latestMessageCode + ",d=0");
                    return;
                }
                me.manageResults(latestMessageCode + ",d!=0", data);
                messageCode = "t>wt,loaded";
            },
        })
    }

    this.moreResult = function () {
        $moreResult.hide();
        $waiting.show();
        $pageNumber.val(parseInt($moreResult.data('currentPage')) + 1);
        ajax({
            url: me.resultUrl,
            method: "get",
            data: $filtersForm.serialize(),
            headers: headers || {},
            success: function (data) {
                me.mapContent(data.data);
                $moreResult.data('currentPage', data.pages.page);
                if ($moreResult.data('pageCount') > parseInt(data.pages.page)) {
                    $moreResult.show();
                }
            },
        })
    }

    this.mapFilters = function (filters) {
        $filters.empty();
        $filters.append(view("visa/visa-filters", { filters: filters }));
        checkboxes();

        $filtersForm = $filters.find('.visa-filter-form');
        $pageNumber = $filtersForm.find(".page-number");
    }

    this.configFilters = function (filters, pages) {
        $filters.find(".apply-filter").click(function () {
            me.applyFilters();
            $filters.next().click();
        });
        $filters.find(".reset-filter").click(function () {
            $filtersForm[0].reset();
            $filters.empty();
            me.mapFilters(filters);
            me.configFilters(filters, pages);
            me.applyFilters();
            $filters.next().click();
        });
        $filters.find(".session-id").val(dataActive.location.query.sessionId);
        $pageNumber.val(1);
        this.loadMore(pages);
    }

    this.loadMore = function (pages) {
        if ($moreResult.data('pageCount') == undefined) {
            $moreResult.click(me.moreResult)
        }
        $moreResult.data('pageCount', pages.pageCount);
        $moreResult.data('currentPage', pages.page);
        if (pages.pageCount > parseInt(pages.page)) {
            $moreResult.show();
        } else {
            $moreResult.hide();
        }
    }

    this.applyFilters = function () {
        $resultItem.empty();
        $moreResult.hide();
        $waiting.show();
        $pageNumber.val(1);
        ajax({
            url: me.resultUrl,
            method: "get",
            data: $filtersForm.serialize(),
            headers: headers || {},
            success: function (data) {
                me.mapContent(data.data);
                $pageNumber.val(2);
                me.loadMore(data.pages);
            },
        })
    }

    this.mapContent = function (data) {
        $waiting.hide();
        data.forEach(item => {
            $resultItem.append(view("visa/visa-item", { item: item }));
        });

    }

    this.manageResults = function (latestMessageCode, data) {
        switch (latestMessageCode) {
            case "t<wt,p=100,d=0":
                me.showNotFoundError();
                me.kill();
                break;
            case "t<wt,p=100,d!=0":
                me.mapData(data);
                me.kill();
                break;
            case "t>wt,p=0":
            case "t>wt,p<100,d=0":
                me.showAlertQuestion("No Response!", "Wait More", "Try.Again!");
                break;
            case "t>wt,p<100,d!=0":
                me.mapData(data);
                break;
            case "t>wt,p=100,d=0":
                me.showNotFoundError();
                me.kill();
                break;
            case "t>wt,p=100,d!=0":
                me.mapData(data);
                me.kill();
                break;
            case "t>wt,loaded,p=100,d!=0":
                me.alertReloade("More result found. Do  you wish to reload now?", "N0", "Yes");
                break;
            default:
                me.showNotFoundError();
                me.kill();
                break;
        }


    }

    this.showNotFoundError = function () {
        $waiting.hide();
        handler.domNode.find('.show-not-found-message').show();
    }

    this.showAlertQuestion = function (text, cancelText, confirmText) {
        swal({
            text: trans(text),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: trans(cancelText),
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: trans(confirmText),
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                }
            }
        })
            .then((response) => {
                if (response == true) {
                    searchBtn.click();
                }
            });
    }

    this.alertReloade = function (text, cancelText, confirmText) {
        swal({
            text: trans(text),
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: {
                    text: trans(cancelText),
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: trans(confirmText),
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                }
            }
        })
            .then((response) => {
                if (response == true) {
                    me.kill();
                    ajax({
                        url: dataActive.location.marketplace + "/v1/search/refresh",
                        method: "get",
                        data: {
                            sessionId: dataActive.location.query.sessionId
                        },
                        success: function (filters) {
                            $resultItem.empty();
                            me.mapContent(data.data, data.pages);
                            me.mapFilters(filters);
                            me.configFilters(filters, data.pages);

                        }
                    })
                }
            });
    }

    this.mapData = function (data) {
        me.mapContent(data.data, data.pages);
        me.mapFilters(data.filters);
        me.configFilters(data.filters, data.pages);
    }

    this.afterAjaxError = function () {
        dataActive.pageRoute('index');
    };

    this.kill = function () {
        clearInterval(intervalId);
        clearInterval(progressFinished);
    }

    if ($(window).width() > 992) {
        dataActive.hooks.register('afterPageRoute', function () {
            $('.sticky-col').stick_in_parent({
                parent: $('#sticky-parent')
            });
        });
    }

    ///>initial codes
    if ($(window).width() < 992) {
        mobileFilters();
        magnificLightbox();
    }

    this.checkProgress();
    if (!isProgressFinished) {
        intervalId = setInterval(this.checkProgress, delay);
        progressFinished = setInterval(this.progressFinished, waitTime);
    }

    dataActive.hooks.register('changeCurrency', function (currency) {
        if (!currencyChangeAlert()) {
            return;
        }
        if (!$.isEmptyObject(currency)) {
            dataActive.updateConfig('currentCurrency', currency);
            searchBtn.click();
        }
    });
    dataActive.hooks.register('changeLanguage', function (language) {
        if (languageChangeAlert()) {
            return language;
        }
    });

    dataActive.hooks.register('beforePageRoute', function (configs) {
        me.kill();
        return configs;
    });
}