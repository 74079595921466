function HotelVoucherProcess(handler) {
    this.showUrl = dataActive.location.marketplace + "/v1/book/" + dataActive.location.query.referenceId + "/voucher";
    var me = this;
    var leadPassenger = '';
    var loginInformation = getStorage("loginInformation");

    this.voucherDetail = function () {
        dataActive.hooks.clear('afterAjaxError');
        ajax({
            url: me.showUrl,
            method: "get",
            headers: { 'Authorization': 'Bearer ' + loginInformation.token },
            data: {},
            success: function (data) {
                me.mapContent(data.data);
            },
        });
    }

    this.mapContent = function (data) {
        if (dataActive.location.path === "/hotel-voucher-custom") {
            handler.domNode.find('.agent-item').remove()
        } else {
            handler.domNode.find('.site-config').append(view("partials/site-config", { bookedBy: data.bookedBy }));
        }
        handler.domNode.find(".hotel-summary-voucher-page").append(view("hotel/voucher-page-hotel-details", { data: data }));
        var roomNumber = data.queryParams.rooms_no;
        var adultsNumber = 0;
        var childNumber = 0;
        for (var i = 0; i < roomNumber; i++) {
            adultsNumber += parseInt(data.queryParams.rooms_detail[i]["adults"]);
            childNumber += parseInt(data.queryParams.rooms_detail[i]["children"]);
        }
        handler.domNode.find(".search-detail-table").append(view("hotel/voucher-page-search-detail-table", { data: data, adultsNumber: adultsNumber, childNumber: childNumber }));
        data.serviceInfo.selectedCombination.forEach((item, index) => {
            var roomPassengers = data.passengerInfo[index];
            if (index == 0) {
                leadPassenger = roomPassengers.adult[index][data.passsengersForm.all.first_name[0]['caption']] + '-' + roomPassengers.adult[index][data.passsengersForm.all.last_name[0]['caption']];
            }
            var names = [];
            roomPassengers.adult.forEach((item, index) => {
                names.push(`${roomPassengers.adult[index][data.passsengersForm.all.first_name[0]['caption']]} ${roomPassengers.adult[index][data.passsengersForm.all.last_name[0]['caption']]} ${'(adult)'}`);
            });
            if (!$.isEmptyObject(roomPassengers.child)) {
                roomPassengers.child.forEach((item, index) => {
                    names.push(`${roomPassengers.child[index][data.passsengersForm.all.first_name[0]['caption']]} ${roomPassengers.child[index][data.passsengersForm.all.last_name[0]['caption']]} ${'(child)'}`);
                });
            }
            handler.domNode.find(".passenger-information").append(view("hotel/voucher-page-passenger-table", { item: item, data: data, adultsNumber: adultsNumber, childNumber: childNumber, names: names, roomNumber: index }));
        })
        if (!$.isEmptyObject(data.booking_info.hotelPolicy)) {
            handler.domNode.find('.hotel-details').append(view('hotel/hotel-details-in-voucher', { policy: data.booking_info.hotelPolicy }))
        }
        if (!$.isEmptyObject(data.booking_info.remarks)) {
            handler.domNode.find('.hotel-important-information').append(view('hotel/hotel-important-information-in-voucher', { data: data.booking_info.remarks }))
        }
        if (data.invoiceStatus == 'not_paid') {
            handler.domNode.find('.pay-button').append("<button class='btn btn-success issue' type='button'>" + trans('Pay') + "</button>");
        }

        handler.domNode.find('.issue').click(function () {
            dataActive.pageRoute("passengerInfo", { referenceId: data.bookingReferenceId, type: "hotel" });
        });

        handler.domNode.find('.print').on('click', function () {
            document.title = 'Hotel-Voucher-' + leadPassenger.replace(/ /g, "-");
            window.print();
        });


        handler.domNode.find('.download-voucher-ticket').on('click', function () {
            let exportName = 'Hotel-Ticket-' + leadPassenger.replace(/ /g, "-");
            ajax({
                url: dataActive.location.marketplace + "/v1/export/",
                method: "POST",
                data: {
                    service: 'voucherAndTicket',
                    exportName: exportName,
                    exportTemplate: 'main-hotel-voucher',
                    exportFormat: 'pdf',
                    referenceId: data.bookingReferenceId,
                    lang: (dataActive.location.query.lang || 'EN').toLowerCase(),
                },
                success: function (data) {
                    if (data.status == true) {
                        window.open(data.openLink, '_blank')
                    }
                },
                error: function (data) {

                }
            });
        });
    }
    this.init = function () {
        this.voucherDetail();
    }
    this.init();
}
