function VisaVoucherProcess(handler) {
    this.showUrl = dataActive.location.marketplace + "/v1/book/" + dataActive.location.query.referenceId + "/voucher";
    var me = this;
    var leadPassenger = '';
    var loginInformation = getStorage("loginInformation");

    this.voucherDetail = function () {
        dataActive.hooks.clear('afterAjaxError');
        ajax({
            url: me.showUrl,
            method: "get",
            data: {},
            headers: { 'Authorization': 'Bearer ' + loginInformation.token },
            success: function (data) {
                me.mapContent(data.data);
            },
        });
    }

    this.mapContent = function (data) {
        handler.domNode.find('.site-config').append(view("partials/site-config", { bookedBy: data.bookedBy }));
        handler.domNode.find(".visa-summary-voucher-page").append(view("visa/voucher-page-visa-title-section", { data: data }));
        handler.domNode.find('.visa-detail-tabel').append(view("visa/visa-detail", { data: data }));
        var passengers = data.passengerInfo;
        var passengerForm = data.passsengersForm;

        passengers.forEach((passenger, index) => {
            if (index == 0) {
                leadPassenger = passenger[passengerForm['all']['first_name'][0]['caption']] + '-' + passenger[passengerForm['all']['last_name'][0]['caption']];
            }
            let documents = data.booking_info.passengers[index]['documents'];
            handler.domNode.find('.passenger-table-body').append(view('visa/passenger-info-in-voucher', { passenger: passenger, passengerForm: passengerForm, data: data, documents: documents }));
        });
        if (data.invoiceStatus == 'not_paid') {
            handler.domNode.find('.pay-button').append("<button class='btn btn-success issue' type='button'>" + trans('Pay') + "</button>");
        }

        handler.domNode.find('.issue').click(function () {
            dataActive.pageRoute("passengerInfo", { referenceId: data.bookingReferenceId, type: "visa" });
        });

        handler.domNode.find('.print').on('click', function () {
            document.title = 'Visa-Voucher-' + leadPassenger.replace(/ /g, "-");
            window.print();
        });

        handler.domNode.find('.download-voucher-ticket').on('click', function () {
            let exportName = 'Visa-Voucher-' + leadPassenger.replace(/ /g, "-");
            ajax({
                url: dataActive.location.marketplace + "/v1/export/",
                method: "POST",
                data: {
                    service: 'voucherAndTicket',
                    exportName: exportName,
                    exportTemplate: 'main-visa-voucher',
                    exportFormat: 'pdf',
                    referenceId: data.bookingReferenceId,
                    lang: (dataActive.location.query.lang || 'EN').toLowerCase(),
                },
                success: function (data) {
                    if (data.status == true) {
                        window.open(data.openLink, '_blank')
                    }
                },
                error: function (data) {

                }
            });
        });
    }
    this.init = function () {
        this.voucherDetail();
    }
    this.init();
}