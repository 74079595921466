function FooterProcess (handler) {
     var currencies = handler.domNode.find(".footer-currency");
     var langs = handler.domNode.find(".footer-lang");
     var me = this;
     
     langs.find("ul>li").each((index,lang) => {
          $(lang).on('click', function () {
               dataActive.$head.find('.font-style').remove();
               var language = $(this).data('value');
               language = dataActive.hooks.call('changeLanguage', language);
               if (!$.isEmptyObject(language)) {
                    let currentLang =  dataActive.location.query.lang;
                   dataActive.location.query.lang = language.iso_code;
                   me.changeCalendar();
                   dataActive.siteLanguage();
                   if(dataActive.location.address.includes('voucher') || dataActive.location.address.includes('ticket')){
                    let address = dataActive.location.address;
                    let href = address.replace(currentLang, language.iso_code);
                    window.location.href= href;
                    } else {
                         dataActive.pageRoute('index');
                    }
               }
           });
     });

     currencies.find("ul>li").each((index,currency) => {
          $(currency).on('click', function () {
               me.changeCurrency();
               var currencyValue = $(this).data('value');
               currencyValue = dataActive.hooks.call('changeCurrency', currencyValue);
               if (!$.isEmptyObject(currencyValue)) {
                   dataActive.updateConfig('currentCurrency', currencyValue);
                   dataActive.pageRoute('index');
               }
           });
     })

     this.changeCalendar = function () {
          dataActive.updateConfig('currentCalendar', (dataActive.location.query.lang == 'FA' ? 'jalali' : 'gregorian'));
     }

     this.changeCurrency = function () {
          dataActive.updateConfig('currentCurrency', getConfig('currentCurrency', getConfig('currency')));
     }
}