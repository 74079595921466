function EventLandingPageProcess(handler) {

    var me = this;
    var loginInformation = getStorage("loginInformation");
    if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
        var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
    }

    this.searchEvent = function () {
        ajax({
            url: dataActive.location.marketplace + "/v1/landing/event",
            headers: headers || {},
            method: "post",
            data: { maxResultCount: 4 },
            success: function (data) {
                me.mapContent(data.data);
            },
            error: function (data, validation) {
                dataActive.defaultAjaxError(data, validation);
            }
        });
    }



    this.mapContent = function (data) {
        var $eventItem = handler.domNode.find('.suggested-event');
        if (!$.isEmptyObject(data)) {
            handler.domNode.removeClass('d-none');
            data.forEach((item) => {
                $eventItem.append(view("event/suggested-event-item", { item: item }));
            });
            OwlCarousel(data.length);
        } else {
            // $eventItem.append(`<div class="suggested-not-available">${trans('suggested_event_not_available')}</div>`);
        }
    }

    function OwlCarousel(cardsCount) {
        var navText = ['<span class="fa fa-angle-left"><span>', '<span class="fa fa-angle-right"></span>'];
        if (dataActive.dir == 'rtl') {
            navText = ['<span class="fa fa-angle-right"><span>', '<span class="fa fa-angle-left"></span>'];
        }
        handler.domNode.find('.owl-carousel').each(function () {
            var $carousel = $(this);
            var items = $carousel.data("items")
            var timeout = $carousel.data("autoplay-timeout")
            $carousel.owlCarousel({
                dots: false,
                items: items,
                loop: cardsCount > items ? true : false,
                autoplay: $carousel.data("autoplay"),
                autoplayTimeout: timeout,
                navText: navText,
                autoplayHoverPause: true,
                responsive: {
                    0: {
                        items: 1,
                        dots: true,
                        nav: false
                    },
                    992: {
                        items: items,
                        dots: false,
                        nav: $carousel.data("nav")
                    }
                }
            });
            $carousel.on('mouseleave',function(){
                $carousel.trigger('stop.owl.autoplay');
                $carousel.trigger('play.owl.autoplay', [timeout]);
             })
        });
    }

    this.init = function () {
        this.searchEvent();
    }
    this.init();

}